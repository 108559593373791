import { Fragment, useState } from 'react';
import { useAtomValue } from 'jotai';

import { TCategory } from '../../../../types';
import { getWeeksOnMarket } from '../../../../services/getWeeksOnMarket';
import { BasicModal } from '../../../../components/Common/BasicModal';
import ActiveCompetition from '../../../../components/Slide/slides/marketAnalysis/marketOverview/components/activeCompetition/web/ActiveCompetition';
import { reportPropertiesAtom } from '../../state/reportPropertiesAtom';
import { competitionGraphAtom } from '../../state/competitionGraphAtom';
import { MarketAssessmentCard } from './MarketAssessmentCard';
import bgImg from './assets/fresh-stale-dark.svg?url';
import bgImgLight from './assets/fresh-stale.svg?url';

interface ActiveCompetitionGaugeProps {
  trackClick?: () => void;
  category?: TCategory;
}

export const ActiveCompetitionGauge = ({ trackClick, category }: ActiveCompetitionGaugeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const properties = useAtomValue(reportPropertiesAtom);
  const data = useAtomValue(competitionGraphAtom);

  const competitionOpenFunction = data?.count ? () => openPopup() : () => {};
  const onMarket = properties
    .filter(prop => prop.status === 'active' && !prop.excluded && !!prop.property.daysOnMarket)
    .map(prop => prop.property);
  const countOfFresh = onMarket.filter(p => p.daysOnMarket <= 84).length;
  const countOfStale = onMarket.filter(p => p.daysOnMarket > 84).length;
  const weeksOnMarket = getWeeksOnMarket(onMarket);

  const openPopup = () => {
    setIsOpen(true);
    trackClick?.();
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const content = !data?.count ? 'N/A' : countOfFresh > countOfStale ? 'Fresh' : 'Stale';
  const description = !weeksOnMarket
    ? ''
    : `The average active comparable has been on market for ${weeksOnMarket} ${
        weeksOnMarket > 1 ? 'weeks' : 'week'
      }`;

  return (
    <Fragment>
      <MarketAssessmentCard
        headline='Fresh/Stale'
        content={content}
        description={description}
        bgImg={bgImg}
        bgImgLight={bgImgLight}
        clicked={competitionOpenFunction}
        hasCircle
        disabled={!data?.count}
      />

      <BasicModal id='marketOverview' isOpen={isOpen} onClose={closePopup}>
        <ActiveCompetition category={category || 'traditional'} />
      </BasicModal>
    </Fragment>
  );
};
