import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { NumberFormatter } from '../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { TProperty, TablePropertyStatus } from '../../../../types';

const calculateAverage = (collection: TProperty[], property: keyof TProperty) => {
  const cleanCollection = collection.filter(item =>
    property === 'beds' ? !isNaN(parseFloat(String(item[property]))) : item[property],
  );
  const sum = cleanCollection.reduce((sum, item) => {
    const value = item[property] || '';

    if (Number.isNaN(parseFloat(String(value)))) {
      return sum;
    }

    return sum + Number(value);
  }, 0);

  const average = sum / cleanCollection.length;
  return Math.round(average * 100) / 100;
};

const calculateAveragePricePerSq = (collection: TProperty[], type: TablePropertyStatus) => {
  const pricePropertyName = type !== 'sold' ? 'pricePerSqft' : 'salePricePerSqft';

  const cleanCollection = collection.filter(item => item.size);
  const sum = cleanCollection.reduce((sum, item) => sum + item[pricePropertyName], 0);
  const average = sum / cleanCollection.length;

  return Math.floor(average);
};

function calculateAverageDOM(properties: TProperty[], type: TablePropertyStatus, isDTC: boolean) {
  if (type === 'pending') return '';

  if (type === 'sold' && isDTC) return calculateAverage(properties, 'daysToClose');

  return calculateAverage(properties, 'daysOnMarket');
}

function calculateAverageSalePrice(properties: TProperty[], type: TablePropertyStatus) {
  if (type !== 'sold') return { salePrice: 'N/A' };

  return {
    salePrice: MoneyFormatter.format(calculateAverage(properties, 'salePrice')),
  };
}

export const calculateAverageRow = (
  properties: TProperty[],
  type: TablePropertyStatus,
  isDTC: boolean,
) => {
  if (!properties) {
    return {};
  }

  const salePrice = calculateAverageSalePrice(properties, type);
  const sqft = calculateAverage(properties, 'size');
  const perSqqft = calculateAveragePricePerSq(properties, type);
  const built = calculateAverage(properties, 'yearBuilt');
  const dom = calculateAverageDOM(properties, type, isDTC);

  return {
    ...salePrice,
    price: MoneyFormatter.format(calculateAverage(properties, 'price')),
    bd: calculateAverage(properties, 'beds'),
    bth: calculateAverage(properties, 'totalBaths'),
    sqft: sqft === Infinity || isNaN(sqft) ? '' : NumberFormatter.format(sqft),
    pricePerSq: perSqqft === Infinity || isNaN(perSqqft) ? '' : MoneyFormatter.format(perSqqft),
    built: built ? Math.round(built) : '',
    cdom: dom ? Math.round(dom) : '',
    label: 'AVERAGES',
    source: '',
  };
};
